import React from 'react'
import "./Header.scss";
import { motion } from 'framer-motion';
import { images } from '../../constants';
import { AppWrap } from '../../wrapper';


const scaleVariants = {
    whileInView: {
        scale: [0, 1],
        opacity: [0, 1],
        transition: {
            duration: 1,
            ease: 'easeInOut'
        }
    }
}

const Header = () => {
    return (
        <div className='app__header app__flex'>
            <div className="spotlight-wrapper">
                <svg 
                    className="spotlight-effect"
                    xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 3787 2842" 
                    fill="none"
                >
                    <g filter="url(#filter)">
                        <ellipse 
                            cx="1924.71" 
                            cy="273.501" 
                            rx="1924.71" 
                            ry="273.501" 
                            transform="matrix(-0.822377 -0.568943 -0.568943 0.822377 3631.88 2291.09)" 
                            fill="white" 
                            fillOpacity="0.21"
                        />
                    </g>
                    <defs>
                        <filter 
                            id="filter" 
                            x="0.860352" 
                            y="0.838989" 
                            width="3785.16" 
                            height="2840.26" 
                            filterUnits="userSpaceOnUse" 
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                            <feGaussianBlur stdDeviation="151" result="effect1_foregroundBlur_1065_8"/>
                        </filter>
                    </defs>
                </svg>
            </div>

            <div className="app__header-content">
                <motion.div
                    whileInView={{ x: [-100, 0], opacity: [0, 1] }}
                    transition={{ duration: 1 }}
                >
                    <div className="app__header-badge">
                        <div className='badge-cmp app__flex'>
                            <span>👋</span>
                            <div style={{ marginLeft: 20 }}>
                                <p className='p-text'> Hello, I am</p>
                                <h1 className='head-text'>Arpit Singh</h1>
                            </div>
                        </div>

                        <div className='tag-cmp app__flex'>
                            <p className='p-text developer-title'>Fullstack Blockchain Developer </p>
                            {/* <p className='p-text'> Smart Contract Developer</p> */}
                        </div>
                    </div>
                </motion.div>

                <motion.div
                    whileInView={{ opacity: [0, 1] }}
                    transition={{ duration: 0.5, delayChildren: 0.5 }}
                    className="app__header-img"
                >
                    <img src={images.arpitai1} alt="profile_bg" />
                    <motion.img
                        whileInView={{ scale: [0, 1] }}
                        transition={{ duration: 1, ease: 'easeInOut' }}
                        className="overlay_circle"
                        src={images.circle}
                        alt="profile_circle"
                    />
                </motion.div>

                <motion.div
                    variant={scaleVariants}
                    whileInView={scaleVariants.whileInView}
                    className='app__header-circles'
                >
                    {[images.flutter, images.redux, images.sass].map((circle, index) => (
                        <div className='circle-cmp app__flex' key={`circle-${index}`}>
                            <img src={circle} alt="circle" />
                        </div>
                    ))}
                </motion.div>
            </div>
        </div>
    )
}

export default AppWrap(Header, 'home');